<template>
  <div style="position: relative!important;">
    <div class="loader-wrap" v-if="loading==true">
      <div class="loader"></div>
    </div>
    <div v-else-if="loading==false">
      <div class="header-additional">
        <div class="container">
          <div class="header-additional-block">
            <div class="header-additional__logo">
              <img src="images/1000-bala.png" width="70" height="30">
              <div class="header-additional__desc">
                {{ $t('mynBala.заголовок') }}
              </div>
            </div>
            <div style="display: flex;width: 70%; justify-content: flex-end;">
              <el-select
                  style="margin-right: 1rem"
                  @change="changeLang()"
                  :placeholder="$t('Qabilet.ВыберитеЯзык')"
                  v-model="locale"
                  filterable>
                <el-option
                    key="kz"
                    :label="$t('Qabilet.Казахский')"
                    value="kz">
                </el-option>
                <el-option
                    key="ru"
                    :label="$t('Qabilet.Русский')"
                    value="ru">
                </el-option>
              </el-select>
              <div class="header-additional__date">
                <div class="current-date mr-4">
                  {{ date }}
                </div>
                <div class="current-time">
                  {{ time }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="mb-statistic">
        <div class="container">
          <div class="mb-statistic__title-row">
            <div class="title">
              {{ $t('mynBala.мынбалаВЦифрах') }}
            </div>
            <div style="display: flex;">
              <div style="margin-right: 25px" class="select select--date select--region">
                <el-select
                    @change="updateInfo()"
                    v-model="qabiletDateFrom"
                    filterable
                    placeholder="Выберите год"
                >
                  <el-option
                      v-for="item in years"
                      :key="item.id+1"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>

              </div>

            </div>
          </div>
          <div class="mb-statistic__cards-row" v-if="qabiletDateFrom==2022">
            <div class="mb-card-first">
              <div class="mb-card-first__title">
                {{ $t('mynBala.данныеПоПодаче') }}
              </div>
              <div class="mb-card-first__content">
                <div v-if="chartPatr" class="mb-card-first__chart">
                  <apexcharts type="radialBar" height="273" :options="chartOptions" :series="series"></apexcharts>
                </div>
                <div class="mb-card-first__info">
                  <!--                  <div class="mb-card-first__info-1">-->
                  <!--                    <span>{{ participation[6].all.total + participation[5].all.total | Numerize }}</span>- -->
                  <!--                    {{ $t('mynBala.Всего5-6') }}-->
                  <!--                  </div>-->
                  <div class="mb-card-first__info-2">
                    <span>{{ participation[6].all.total + participation[5].all.total | Numerize }}</span>-
                    {{$t('mynBala.Всегоучеников5-6 классов')}}
                  </div>
                  <div class="mb-card-first__info-3">
                    <span>{{ participation[6].approved.total + participation[5].approved.total | Numerize }}</span>-
                    {{$t('mynBala.Зарегистрировано')}}
                  </div>
                  <div class="mb-card-first__info-4">
                    <span>{{ participation[6].declined.total + participation[5].declined.total | Numerize }}</span>-
                    {{$t('mynBala.Незарегистрировано')}}
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row flex-wrap-mob">
              <div class="mb-card-second">
                <div class="mb-card-second__row mb-5">
                  <div class="mb-card-second__card mr-4">
                    <div class="mb-card-second__card-title">
                      1 {{ $t('mynBala.этап') }}
                    </div>
                    <div class="mb-card-second__chart">
                      <div
                          v-bind:style="{'height':((participation[6].approved.total + participation[5].approved.total)/(participation[6].all.total + participation[5].all.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-blue"></div>
                      <div
                          v-bind:style="{'height':((participation[6].second_stage_approved.total + participation[5].second_stage_approved.total)/(participation[6].all.total + participation[5].all.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-green"></div>
                      <div
                          v-bind:style="{'height':((  participation[6].second_stage_participants.total + participation[5].second_stage_participants.total)/(participation[6].all.total + participation[5].all.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-yellow"></div>
                    </div>
                    <div class="chart-label-wrapper">
                      <div class="chart-label"><span
                          style="width:100%">{{
                          (participation[6].approved.total + participation[5].approved.total)| Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          participation[6].second_stage_approved.total + participation[5].second_stage_approved.total| Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          participation[6].second_stage_participants.total + participation[5].second_stage_participants.total| Numerize
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="mb-card-second__card">
                    <div class="mb-card-second__card-title">
                      2 {{ $t('mynBala.этап') }}
                    </div>
                    <div class="mb-card-second__chart">
                      <div
                          v-bind:style="{'height':((participation[6].second_stage_participants.total + participation[5].second_stage_participants.total )/(participation[6].second_stage_participants.total + participation[5].second_stage_participants.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-blue"></div>
                      <div
                          v-bind:style="{'height':((participation[6].finished.total + participation[5].finished.total)/(participation[6].second_stage_participants.total + participation[5].second_stage_participants.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-green"></div>
                      <div
                          v-bind:style="{'height':((participation[6].winners.total + participation[5].winners.total)/(participation[6].second_stage_participants.total + participation[5].second_stage_participants.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-yellow"></div>
                    </div>
                    <div class="chart-label-wrapper">
                      <div class="chart-label"><span
                          style="width:100%">{{
                          participation[6].second_stage_participants.total + participation[5].second_stage_participants.total | Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          participation[6].finished.total + participation[5].finished.total| Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          participation[6].winners.total + participation[5].winners.total| Numerize
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-card-second__desc justify-content-center">
                  <div class="mb-card-second__circle"></div>
                  <div>
                    <div>1 {{ $t('mynBala.этап') }} - {{ $t('mynBala.Финалисты') }}</div>
                    <div>2 {{ $t('mynBala.этап') }} - {{ $t('mynBala.Победители') }}</div>
                  </div>
                </div>
                <div class="mb-card-second__desc-row">
                  <div class="mb-card-second__desc justify-content-center">
                    <div class="mb-card-second__circle mb-card-second__circle--blue"></div>
                    <div>
                      {{ $t('mynBala.Допущеноктестированию') }}
                    </div>
                  </div>
                  <div class="mb-card-second__desc justify-content-center">
                    <div class="mb-card-second__circle mb-card-second__circle--green"></div>
                    <div>
                      {{ $t('mynBala.Прошлитестирование') }}

                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-card-third">
                <div class="mb-card-third__row mb-3">
                  <div class="mb-card-third__card">
                    <div class="mb-card-third__card-title">
                      {{ $t('mynBala.РаспределениесСШОД') }}
                    </div>
                    <div class="mb-card-third__chart">
                      <div
                          v-bind:style="{'height':((enrolled.total)/(enrolled.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-blue"></div>
                      <div
                          v-bind:style="{'height':((enrolled.applied)/(enrolled.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-green"></div>
                      <div style="background-color:#F00070"
                           v-bind:style="{'height':((enrolled.free_places)/(enrolled.total))*100+'%'}"
                           class="chart-column-mynbala chart-column-mynbala-green"></div>

                    </div>
                    <div class="chart-label-wrapper">
                      <div class="chart-label"><span
                          style="width:100%">{{
                          enrolled.total| Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          enrolled.applied| Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          enrolled.free_places| Numerize
                        }}</span>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="mb-card-third__desc mb-3">
                  <div class="mb-card-third__circle mb-card-third__circle--blue"></div>
                  <div>
                    {{ $t('mynBala.Всего мест') }}
                  </div>
                </div>
                <div class="mb-card-third__desc mb-3">
                  <div class="mb-card-third__circle mb-card-third__circle--green"></div>
                  <div>
                    {{ $t('mynBala.ПодалиЗаявкиНаЗачисление') }}
                  </div>
                </div>
                <div class="mb-card-third__desc">
                  <div class="mb-card-third__circle mb-card-third__circle--pink"></div>
                  <div>
                    {{ $t('mynBala.свободныхМест') }}

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-statistic__cards-row" v-if="qabiletDateFrom!=2022">
            <div class="mb-card-first">
              <div class="mb-card-first__title">
                {{ $t('mynBala.данныеПоПодаче') }}
              </div>
              <div class="mb-card-first__content">
                <div v-if="chartPatr" class="mb-card-first__chart">
                  <apexcharts type="radialBar" height="273" :options="chartOptions" :series="series"></apexcharts>
                </div>
                <div class="mb-card-first__info">
                  <!--                  <div class="mb-card-first__info-1">-->
                  <!--                    <span>{{ participation[6].all.total + participation[5].all.total | Numerize }}</span>- -->
                  <!--                    {{ $t('mynBala.Всего5-6') }}-->
                  <!--                  </div>-->
                  <div class="mb-card-first__info-2">
                    <span>{{ participation[6].all.total + participation[5].all.total | Numerize }}</span>-
                    {{ $t('mynBala.ВсегоПоданоЗаявок') }}
                  </div>
                  <div class="mb-card-first__info-3">
                    <span>{{ participation[6].approved.total + participation[5].approved.total | Numerize }}</span>-
                    {{ $t('mynBala.подтвержденоЗаявок') }}
                  </div>
                  <div class="mb-card-first__info-4">
                    <span>{{ participation[6].declined.total + participation[5].declined.total | Numerize }}</span>-
                    {{ $t('mynBala.отказано') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row flex-wrap-mob">
              <div class="mb-card-second">
                <div class="mb-card-second__row mb-5">
                  <div class="mb-card-second__card mr-4">
                    <div class="mb-card-second__card-title">
                      1 {{ $t('mynBala.этап') }}
                    </div>
                    <div class="mb-card-second__chart">
                      <div
                          v-bind:style="{'height':((participation[6].approved.total + participation[5].approved.total)/(participation[6].all.total + participation[5].all.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-blue"></div>
                      <div
                          v-bind:style="{'height':((participation[6].second_stage_approved.total + participation[5].second_stage_approved.total)/(participation[6].all.total + participation[5].all.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-green"></div>
                      <div
                          v-bind:style="{'height':((  participation[6].second_stage_participants.total + participation[5].second_stage_participants.total)/(participation[6].all.total + participation[5].all.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-yellow"></div>
                    </div>
                    <div class="chart-label-wrapper">
                      <div class="chart-label"><span
                          style="width:100%">{{
                          (participation[6].approved.total + participation[5].approved.total)| Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          participation[6].second_stage_approved.total + participation[5].second_stage_approved.total| Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          participation[6].second_stage_participants.total + participation[5].second_stage_participants.total| Numerize
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="mb-card-second__card">
                    <div class="mb-card-second__card-title">
                      2 {{ $t('mynBala.этап') }}
                    </div>
                    <div class="mb-card-second__chart">
                      <div
                          v-bind:style="{'height':((participation[6].second_stage_participants.total + participation[5].second_stage_participants.total )/(participation[6].second_stage_participants.total + participation[5].second_stage_participants.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-blue"></div>
                      <div
                          v-bind:style="{'height':((participation[6].finished.total + participation[5].finished.total)/(participation[6].second_stage_participants.total + participation[5].second_stage_participants.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-green"></div>
                      <div
                          v-bind:style="{'height':((participation[6].winners.total + participation[5].winners.total)/(participation[6].second_stage_participants.total + participation[5].second_stage_participants.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-yellow"></div>
                    </div>
                    <div class="chart-label-wrapper">
                      <div class="chart-label"><span
                          style="width:100%">{{
                          participation[6].second_stage_participants.total + participation[5].second_stage_participants.total | Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          participation[6].finished.total + participation[5].finished.total| Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          participation[6].winners.total + participation[5].winners.total| Numerize
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-card-second__desc justify-content-center">
                  <div class="mb-card-second__circle"></div>
                  <div>
                    <div>1 {{ $t('mynBala.этап') }} - {{ $t('mynBala.Финалисты') }}</div>
                    <div>2 {{ $t('mynBala.этап') }} - {{ $t('mynBala.Победители') }}</div>
                  </div>
                </div>
                <div class="mb-card-second__desc-row">
                  <div class="mb-card-second__desc justify-content-center">
                    <div class="mb-card-second__circle mb-card-second__circle--blue"></div>
                    <div>
                      {{ $t('mynBala.Допущеноктестированию') }}
                    </div>
                  </div>
                  <div class="mb-card-second__desc justify-content-center">
                    <div class="mb-card-second__circle mb-card-second__circle--green"></div>
                    <div>
                      {{ $t('mynBala.Прошлитестирование') }}

                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-card-third">
                <div class="mb-card-third__row mb-3">
                  <div class="mb-card-third__card">
                    <div class="mb-card-third__card-title">
                      {{ $t('mynBala.РаспределениесСШОД') }}
                    </div>
                    <div class="mb-card-third__chart">
                      <div
                          v-bind:style="{'height':((enrolled.total)/(enrolled.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-blue"></div>
                      <div
                          v-bind:style="{'height':((enrolled.applied)/(enrolled.total))*100+'%'}"
                          class="chart-column-mynbala chart-column-mynbala-green"></div>
                      <div style="background-color:#F00070"
                           v-bind:style="{'height':((enrolled.free_places)/(enrolled.total))*100+'%'}"
                           class="chart-column-mynbala chart-column-mynbala-green"></div>

                    </div>
                    <div class="chart-label-wrapper">
                      <div class="chart-label"><span
                          style="width:100%">{{
                          enrolled.total| Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          enrolled.applied| Numerize
                        }}</span>
                      </div>
                      <div class="chart-label"><span
                          style="width:100%">{{
                          enrolled.free_places| Numerize
                        }}</span>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="mb-card-third__desc mb-3">
                  <div class="mb-card-third__circle mb-card-third__circle--blue"></div>
                  <div>
                    {{ $t('mynBala.Всего мест') }}
                  </div>
                </div>
                <div class="mb-card-third__desc mb-3">
                  <div class="mb-card-third__circle mb-card-third__circle--green"></div>
                  <div>
                    {{ $t('mynBala.ПодалиЗаявкиНаЗачисление') }}
                  </div>
                </div>
                <div class="mb-card-third__desc">
                  <div class="mb-card-third__circle mb-card-third__circle--pink"></div>
                  <div>
                    {{ $t('mynBala.свободныхМест') }}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="mb-stat-region">
        <div class="container">
          <div class="title">
            {{ $t('mynBala.мынбалаВЦифрах') }}

            <el-select
                style="margin-left: 2rem"
                :disabled="filterLoading"
                @change="filterMynbalaNUM()"
                :placeholder="$t('Qabilet.Регион')"
                v-model="region_id_mynbalaNum"
                filterable>
              <el-option
                  :key="0+2"
                  :label="$t('МедальЕлбасы.ПоказатьВсе')"
                  :value="0">
              </el-option>
              <el-option
                  v-for="item in regions"
                  :key="item.id+3"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>

          <div v-if="participation!=false &&qabiletDateFrom==2022" class="mb-stat-region__table-block">

            <table class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--main table-striped">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="3" class="mb-stat-region-table__header">№</td>
                <td rowspan="3" class="mb-stat-region-table__header">{{ $t('mynBala.Показатели') }}</td>
                <th colspan="5" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">5
                  {{ $t('mynBala.класс') }}
                </th>
                <th colspan="5" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">6
                  {{ $t('mynBala.класс') }}
                </th>
                <td rowspan="3" class="mb-stat-region-table__header">{{ $t('mynBala.ОбщийИтог') }}</td>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.РусскийЯзыкСдачиТеста') }}</th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Казахскийязыксдачитеста') }}</th>
                <th colspan="2" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Пол') }}
                </th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Итого') }}</th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.РусскийЯзыкСдачиТеста') }}</th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Казахскийязыксдачитеста') }}</th>
                <th colspan="2" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Пол') }}
                </th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Итого') }}</th>
              </tr>
              <tr class="mb-stat-region-table__header-3">
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Мужской') }}</th>
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Женский') }}</th>
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Мужской') }}</th>
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Женский') }}</th>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">1</td>
                <td>{{ $t('mynBala.Всегоучеников5-6 классов') }}</td>
                <td>{{ participation[5].all.rus | Numerize }}</td>
                <td>{{ participation[5].all.kaz | Numerize }}</td>
                <td>{{ participation[5].all.male | Numerize }}</td>
                <td>{{ participation[5].all.female | Numerize }}</td>
                <td>{{ participation[5].all.total | Numerize }}</td>
                <td>{{ participation[6].all.rus | Numerize }}</td>
                <td>{{ participation[6].all.kaz | Numerize }}</td>
                <td>{{ participation[6].all.female | Numerize }}</td>
                <td>{{ participation[6].all.male | Numerize }}</td>
                <td>{{ participation[6].all.total | Numerize }}</td>
                <td>{{ participation[6].all.total + participation[5].all.total | Numerize }}</td>
              </tr>
              <tr class="mb-stat-region-table__row">
                <td scope="row">1.1</td>
                <td>{{ $t('mynBala.Зарегистрировано') }}</td>
                <td>{{ participation[5].approved.rus | Numerize }}</td>
                <td>{{ participation[5].approved.kaz | Numerize }}</td>
                <td>{{ participation[5].approved.male | Numerize }}</td>
                <td>{{ participation[5].approved.female | Numerize }}</td>
                <td>{{ participation[5].approved.total | Numerize }}</td>
                <td>{{ participation[6].approved.rus | Numerize }}</td>
                <td>{{ participation[6].approved.kaz | Numerize }}</td>
                <td>{{ participation[6].approved.female | Numerize }}</td>
                <td>{{ participation[6].approved.male | Numerize }}</td>
                <td>{{ participation[6].approved.total | Numerize }}</td>
                <td>{{ participation[6].approved.total + participation[5].approved.total | Numerize }}</td>
              </tr>
              <tr class="mb-stat-region-table__row">
                <td scope="row">1.2</td>
                <td>{{ $t('mynBala.Незарегистрировано') }}</td>
                <td>{{ participation[5].declined.rus | Numerize }}</td>
                <td>{{ participation[5].declined.kaz | Numerize }}</td>
                <td>{{ participation[5].declined.male | Numerize }}</td>
                <td>{{ participation[5].declined.female | Numerize }}</td>
                <td>{{ participation[5].declined.total | Numerize }}</td>
                <td>{{ participation[6].declined.rus | Numerize }}</td>
                <td>{{ participation[6].declined.kaz | Numerize }}</td>
                <td>{{ participation[6].declined.female | Numerize }}</td>
                <td>{{ participation[6].declined.male | Numerize }}</td>
                <td>{{ participation[6].declined.total | Numerize }}</td>
                <td>{{ participation[6].declined.total + participation[5].declined.total | Numerize }}</td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">2</td>
                <td>{{ $t('mynBala.ДопущеноК1ЭтапуТестирования') }}</td>
                <td>{{ participation[5].first_stage_participants.rus | Numerize }}</td>
                <td>{{ participation[5].first_stage_participants.kaz | Numerize }}</td>
                <td>{{ participation[5].first_stage_participants.male | Numerize }}</td>
                <td>{{ participation[5].first_stage_participants.female | Numerize }}</td>
                <td>{{ participation[5].first_stage_participants.total | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.rus | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.kaz | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.female | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.male | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.total | Numerize }}</td>
                <td>
                  {{
                    participation[6].first_stage_participants.total + participation[5].first_stage_participants.total| Numerize
                  }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">3</td>
                <td>{{ $t('mynBala.ДопущеноК2ЭтапуТестирования') }}</td>
                <td>{{ participation[5].second_stage_participants.rus | Numerize }}</td>
                <td>{{ participation[5].second_stage_participants.kaz | Numerize }}</td>
                <td>{{ participation[5].second_stage_participants.male| Numerize }}</td>
                <td>{{ participation[5].second_stage_participants.female | Numerize }}</td>
                <td>{{ participation[5].second_stage_participants.total | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.rus | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.kaz | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.female | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.male | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.total | Numerize }}</td>
                <td>
                  {{
                    participation[6].second_stage_participants.total + participation[5].second_stage_participants.total | Numerize
                  }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">3.1</td>
                <td>{{ $t('mynBala.Принялиучастиево2этапетестирования') }}</td>
                <td>{{ participation[5].second_stage_approved.rus | Numerize }}</td>
                <td>{{ participation[5].second_stage_approved.kaz | Numerize }}</td>
                <td>{{ participation[5].second_stage_approved.male | Numerize }}</td>
                <td>{{ participation[5].second_stage_approved.female | Numerize }}</td>
                <td>{{ participation[5].second_stage_approved.total | Numerize }}</td>
                <td>{{ participation[6].second_stage_approved.rus | Numerize }}</td>
                <td>{{ participation[6].second_stage_approved.kaz | Numerize }}</td>
                <td>{{ participation[6].second_stage_approved.female | Numerize }}</td>
                <td>{{ participation[6].second_stage_approved.male | Numerize }}</td>
                <td>{{ participation[6].second_stage_approved.total | Numerize }}</td>
                <td>
                  {{
                    participation[6].second_stage_approved.total + participation[5].second_stage_participants.total | Numerize
                  }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">4</td>
                <td>{{ $t('mynBala.Победители олимпиады') }}</td>
                <td>{{ participation[5].winners.rus | Numerize }}</td>
                <td>{{ participation[5].winners.kaz | Numerize }}</td>
                <td>{{ participation[5].winners.male | Numerize }}</td>
                <td>{{ participation[5].winners.female | Numerize }}</td>
                <td>{{ participation[5].winners.total | Numerize }}</td>
                <td>{{ participation[6].winners.rus | Numerize }}</td>
                <td>{{ participation[6].winners.kaz | Numerize }}</td>
                <td>{{ participation[6].winners.female | Numerize }}</td>
                <td>{{ participation[6].winners.male | Numerize }}</td>
                <td>{{ participation[6].winners.total | Numerize }}</td>
                <td>{{ participation[6].winners.total + participation[5].winners.total| Numerize }}</td>
              </tr>
            </table>
          </div>
          <div v-if="participation!=false &&qabiletDateFrom!=2022" class="mb-stat-region__table-block">

            <table class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--main table-striped">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="3" class="mb-stat-region-table__header">№</td>
                <td rowspan="3" class="mb-stat-region-table__header">{{ $t('mynBala.Показатели') }}</td>
                <th colspan="5" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">5
                  {{ $t('mynBala.класс') }}
                </th>
                <th colspan="5" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">6
                  {{ $t('mynBala.класс') }}
                </th>
                <td rowspan="3" class="mb-stat-region-table__header">{{ $t('mynBala.ОбщийИтог') }}</td>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.РусскийЯзыкСдачиТеста') }}</th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Казахскийязыксдачитеста') }}</th>
                <th colspan="2" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Пол') }}
                </th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Итого') }}</th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.РусскийЯзыкСдачиТеста') }}</th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Казахскийязыксдачитеста') }}</th>
                <th colspan="2" scope="colgroup"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Пол') }}
                </th>
                <th rowspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.Итого') }}</th>
              </tr>
              <tr class="mb-stat-region-table__header-3">
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Мужской') }}</th>
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Женский') }}</th>
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Мужской') }}</th>
                <th scope="col" class="mb-stat-region-table__header">{{ $t('mynBala.Женский') }}</th>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">1</td>
                <td>{{ $t('mynBala.ПоступилоЗаявок') }}</td>
                <td>{{ participation[5].all.rus | Numerize }}</td>
                <td>{{ participation[5].all.kaz | Numerize }}</td>
                <td>{{ participation[5].all.male | Numerize }}</td>
                <td>{{ participation[5].all.female | Numerize }}</td>
                <td>{{ participation[5].all.total | Numerize }}</td>
                <td>{{ participation[6].all.rus | Numerize }}</td>
                <td>{{ participation[6].all.kaz | Numerize }}</td>
                <td>{{ participation[6].all.female | Numerize }}</td>
                <td>{{ participation[6].all.male | Numerize }}</td>
                <td>{{ participation[6].all.total | Numerize }}</td>
                <td>{{ participation[6].all.total + participation[5].all.total | Numerize }}</td>
              </tr>
              <tr class="mb-stat-region-table__row">
                <td scope="row">1.1</td>
                <td>{{ $t('mynBala.ПодтвержденоЗаявок') }}</td>
                <td>{{ participation[5].approved.rus | Numerize }}</td>
                <td>{{ participation[5].approved.kaz | Numerize }}</td>
                <td>{{ participation[5].approved.male | Numerize }}</td>
                <td>{{ participation[5].approved.female | Numerize }}</td>
                <td>{{ participation[5].approved.total | Numerize }}</td>
                <td>{{ participation[6].approved.rus | Numerize }}</td>
                <td>{{ participation[6].approved.kaz | Numerize }}</td>
                <td>{{ participation[6].approved.female | Numerize }}</td>
                <td>{{ participation[6].approved.male | Numerize }}</td>
                <td>{{ participation[6].approved.total | Numerize }}</td>
                <td>{{ participation[6].approved.total + participation[5].approved.total | Numerize }}</td>
              </tr>
              <tr class="mb-stat-region-table__row">
                <td scope="row">1.2</td>
                <td>{{ $t('mynBala.Отклонено заявок') }}</td>
                <td>{{ participation[5].declined.rus | Numerize }}</td>
                <td>{{ participation[5].declined.kaz | Numerize }}</td>
                <td>{{ participation[5].declined.male | Numerize }}</td>
                <td>{{ participation[5].declined.female | Numerize }}</td>
                <td>{{ participation[5].declined.total | Numerize }}</td>
                <td>{{ participation[6].declined.rus | Numerize }}</td>
                <td>{{ participation[6].declined.kaz | Numerize }}</td>
                <td>{{ participation[6].declined.female | Numerize }}</td>
                <td>{{ participation[6].declined.male | Numerize }}</td>
                <td>{{ participation[6].declined.total | Numerize }}</td>
                <td>{{ participation[6].declined.total + participation[5].declined.total | Numerize }}</td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">2</td>
                <td>{{ $t('mynBala.ДопущеноК1ЭтапуТестирования') }}</td>
                <td>{{ participation[5].first_stage_participants.rus | Numerize }}</td>
                <td>{{ participation[5].first_stage_participants.kaz | Numerize }}</td>
                <td>{{ participation[5].first_stage_participants.male | Numerize }}</td>
                <td>{{ participation[5].first_stage_participants.female | Numerize }}</td>
                <td>{{ participation[5].first_stage_participants.total | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.rus | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.kaz | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.female | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.male | Numerize }}</td>
                <td>{{ participation[6].first_stage_participants.total | Numerize }}</td>
                <td>
                  {{
                    participation[6].first_stage_participants.total + participation[5].first_stage_participants.total| Numerize
                  }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">3</td>
                <td>{{ $t('mynBala.ДопущеноК2ЭтапуТестирования') }}</td>
                <td>{{ participation[5].second_stage_participants.rus | Numerize }}</td>
                <td>{{ participation[5].second_stage_participants.kaz | Numerize }}</td>
                <td>{{ participation[5].second_stage_participants.male| Numerize }}</td>
                <td>{{ participation[5].second_stage_participants.female | Numerize }}</td>
                <td>{{ participation[5].second_stage_participants.total | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.rus | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.kaz | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.female | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.male | Numerize }}</td>
                <td>{{ participation[6].second_stage_participants.total | Numerize }}</td>
                <td>
                  {{
                    participation[6].second_stage_participants.total + participation[5].second_stage_participants.total | Numerize
                  }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">3.1</td>
                <td>{{ $t('mynBala.Принялиучастиево2этапетестирования') }}</td>
                <td>{{ participation[5].second_stage_approved.rus | Numerize }}</td>
                <td>{{ participation[5].second_stage_approved.kaz | Numerize }}</td>
                <td>{{ participation[5].second_stage_approved.male | Numerize }}</td>
                <td>{{ participation[5].second_stage_approved.female | Numerize }}</td>
                <td>{{ participation[5].second_stage_approved.total | Numerize }}</td>
                <td>{{ participation[6].second_stage_approved.rus | Numerize }}</td>
                <td>{{ participation[6].second_stage_approved.kaz | Numerize }}</td>
                <td>{{ participation[6].second_stage_approved.female | Numerize }}</td>
                <td>{{ participation[6].second_stage_approved.male | Numerize }}</td>
                <td>{{ participation[6].second_stage_approved.total | Numerize }}</td>
                <td>
                  {{
                    participation[6].second_stage_approved.total + participation[5].second_stage_participants.total | Numerize
                  }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row">4</td>
                <td>{{ $t('mynBala.Победители олимпиады') }}</td>
                <td>{{ participation[5].winners.rus | Numerize }}</td>
                <td>{{ participation[5].winners.kaz | Numerize }}</td>
                <td>{{ participation[5].winners.male | Numerize }}</td>
                <td>{{ participation[5].winners.female | Numerize }}</td>
                <td>{{ participation[5].winners.total | Numerize }}</td>
                <td>{{ participation[6].winners.rus | Numerize }}</td>
                <td>{{ participation[6].winners.kaz | Numerize }}</td>
                <td>{{ participation[6].winners.female | Numerize }}</td>
                <td>{{ participation[6].winners.male | Numerize }}</td>
                <td>{{ participation[6].winners.total | Numerize }}</td>
                <td>{{ participation[6].winners.total + participation[5].winners.total| Numerize }}</td>
              </tr>
            </table>
          </div>

        </div>
      </section>

      <section class="mb-rating">
        <div class="container">
          <div class="title">
            {{ $t('mynBala.заголовок') }}
          </div>
          <div class="mb-rating__sub-title">
            {{ $t('mynBala.РейтингпоКазахстану') }}
          </div>
          <div class="mb-rating__table-block mb-stat-region__table-block">
            <table class="rating-table table table-striped rating-table--kz">
              <thead>
              <tr>
                <th scope="col" class="rating-table__header rating-table__header-helper-2">
                  <div>№</div>
                </th>
                <th scope="col" class="rating-table__header rating-table__header-helper-2">
                  <div>{{ $t('mynBala.Область') }}</div>
                </th>
                <th style="cursor: pointer;user-select: none" @click="regionsRatingRevert('number')" scope="col"
                    class="rating-table__header rating-table__header-helper">
                  <div>{{ $t('mynBala.Зарегестрировано') }}</div>

                  <button
                      v-if="tableRevert.number=='' || tableRevert.number=='asc'"
                      @click="regionsRatingRevert('number')" class="rating-table-revert rotate180"></button>
                  <button
                      v-if="tableRevert.number=='' || tableRevert.number=='desc'"
                      @click="regionsRatingRevert('number')" class="rating-table-revert "></button>

                </th>
                <th style="cursor: pointer ;user-select: none" @click="regionsRatingRevert('first_stage')" scope="col"
                    class="rating-table__header rating-table__header-helper">
                  <div>{{ $t('mynBala.Прошло 1 этап теста') }}</div>

                  <button
                      v-if="tableRevert.first_stage=='' || tableRevert.first_stage=='asc'"
                      @click="regionsRatingRevert('first_stage')" class="rating-table-revert rotate180"></button>
                  <button
                      v-if="tableRevert.first_stage=='' || tableRevert.first_stage=='desc'"
                      @click="regionsRatingRevert('first_stage')" class="rating-table-revert"></button>

                </th>
                <th style="cursor: pointer;user-select: none" @click="regionsRatingRevert('second_stage')" scope="col"
                    class="rating-table__header rating-table__header-helper">
                  <div>{{ $t('mynBala.Прошло 2 этап теста') }}</div>

                  <button
                      v-if="tableRevert.second_stage=='' || tableRevert.second_stage=='asc'"
                      @click="regionsRatingRevert('second_stage')" class="rating-table-revert rotate180"></button>
                  <button
                      v-if="tableRevert.second_stage=='' || tableRevert.second_stage=='desc'"
                      @click="regionsRatingRevert('second_stage')" class="rating-table-revert "></button>

                </th>
                <th style="cursor: pointer;user-select: none" @click="regionsRatingRevert('enrolled')" scope="col"
                    class="rating-table__header rating-table__header-helper">
                  <div>{{ $t('mynBala.ЗачисленовСШОД') }}</div>

                  <button v-if="tableRevert.enrolled=='' || tableRevert.enrolled=='asc'"
                          @click="regionsRatingRevert('enrolled')" class="rating-table-revert rotate180"></button>
                  <button v-if="tableRevert.enrolled=='' || tableRevert.enrolled=='desc'"
                          @click="regionsRatingRevert('enrolled')" class="rating-table-revert "></button>

                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in regionsRating" :key="index+4">
                <td scope="row">{{ item.id }}</td>
                <td v-if="locale=='ru'">{{ item.ru }}</td>
                <td v-if="locale=='kz'">{{ item.kz }}</td>
                <td>{{ item.number | Numerize }}</td>
                <td>{{ item.first_stage | Numerize }}</td>
                <td>{{ item.second_stage | Numerize }}</td>
                <td>{{ item.enrolled | Numerize }}</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div class="d-flex flex-row align-items-center mb-4">
            <div class="mb-rating__sub-title m-0">
              {{ $t('mynBala.РейтингШкол') }}
            </div>
            <div class="select select--region ml-5">
              <el-select
                  :disabled="filterLoading"
                  @change="filterRegionSchool"
                  :placeholder="$t('Qabilet.Регион')"
                  v-model="region_id_school"
                  filterable>
                <el-option
                    v-for="item in regions"
                    :key="item.id+5"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="mb-rating__table-block mb-rating__table-block--schools">
            <table class="rating-table table table-striped rating-table--schools">
              <thead>
              <tr>
                <th scope="col" class="rating-table__header ">№</th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Название') }}
                </th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Область') }}
                </th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Район') }}
                </th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Населенныйпункт') }}
                </th>
                <th style="cursor: pointer;user-select: none" @click="regionsRatingRevertSchool('number')" scope="col"
                    class="rating-table__header rating-table__header-helper">{{ $t('mynBala.Зарегестрировано') }}
                  <button
                      v-if="tableRevertSchool.number=='' || tableRevertSchool.number=='asc'"
                      @click="regionsRatingRevertSchool('number')" class="rating-table-revert rotate180"></button>
                  <button
                      v-if="tableRevertSchool.number=='' ||tableRevertSchool.number=='desc'"
                      @click="regionsRatingRevertSchool('number')" class="rating-table-revert "></button>
                </th>
                <th style="cursor: pointer;user-select: none" @click="regionsRatingRevertSchool('first_stage')" scope="col"
                    class="rating-table__header rating-table__header-helper">{{ $t('mynBala.Прошло 1 этап теста') }}
                  <button
                      v-if="tableRevertSchool.first_stage=='' || tableRevertSchool.first_stage=='asc'"
                      @click="regionsRatingRevertSchool('first_stage')" class="rating-table-revert rotate180"></button>
                  <button
                      v-if="tableRevertSchool.first_stage=='' || tableRevertSchool.first_stage=='desc'"
                      @click="regionsRatingRevertSchool('first_stage')" class="rating-table-revert "></button>
                </th>
                <th style="cursor: pointer;user-select: none" @click="regionsRatingRevertSchool('second_stage')" scope="col"
                    class="rating-table__header rating-table__header-helper">{{ $t('mynBala.Прошло 2 этап теста') }}
                  <button
                      v-if="tableRevertSchool.second_stage=='' ||tableRevertSchool.second_stage=='asc'"
                      @click="regionsRatingRevertSchool('second_stage')" class="rating-table-revert rotate180"></button>
                  <button
                      v-if="tableRevertSchool.second_stage=='' || tableRevertSchool.second_stage=='desc'"
                      @click="regionsRatingRevertSchool('second_stage')" class="rating-table-revert "></button>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in schools" :key="index+6">
                <td scope="row">{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.region }}</td>
                <td>{{ item.city }}</td>
                <td>{{ item.locality }}</td>
                <td class="text-center">{{ item.number | Numerize }}</td>
                <td class="text-center">{{ item.first_stage | Numerize }}</td>
                <td class="text-center">{{ item.second_stage | Numerize }}</td>
              </tr>

              </tbody>
            </table>
          </div>
          <nav class="tasks__pagination">
            <el-pagination
                :disabled="filterLoading"
                :pager-count="11"
                :current-page='statsCurrentPage'
                @current-change="statsFilter"
                layout="prev, pager, next"
                :total="meta*10">
            </el-pagination>
          </nav>
          <div class="select select--region">
            <el-select
                :disabled="filterLoading"
                @change="filterGender()"
                :placeholder="$t('Qabilet.Регион')"
                v-model="region_id_gender"
                filterable>

              <el-option
                  v-for="item in regions"
                  :key="item.id+7"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="mb-stat-region__table-block">
            <table class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--members">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="1" colspan="1"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue"></td>
                <td rowspan="1" colspan="9" class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Количествоучастниковпогендерномупризнаку') }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th rowspan="2" class="mb-stat-region-table__header mb-stat-region-table__header--blue"></th>
                <th rowspan="1" colspan="2" class="mb-stat-region-table__header"> {{
                    $t('mynBala.Зарегестрировано')
                  }}
                </th>
                <th rowspan="1" colspan="2" scope="colgroup" class="mb-stat-region-table__header">
                  {{ $t('mynBala.Прошло 1 этап теста') }}
                </th>
                <th rowspan="1" colspan="2" class="mb-stat-region-table__header">{{
                    $t('mynBala.Прошло 2 этап теста')
                  }}
                </th>
                <th rowspan="1" colspan="2" class="mb-stat-region-table__header">{{ $t('mynBala.ЗачисленовСШОД') }}</th>
              </tr>
              <tr class="mb-stat-region-table__header-3">
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--men">
                  {{ $t('mynBala.Мужчин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--women">
                  {{ $t('mynBala.Женщин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--men">
                  {{ $t('mynBala.Мужчин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--women">
                  {{ $t('mynBala.Женщин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--men">
                  {{ $t('mynBala.Мужчин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--women">
                  {{ $t('mynBala.Женщин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--men">
                  {{ $t('mynBala.Мужчин') }}
                </th>
                <th scope="col" class="mb-stat-region-table__header mb-stat-region-table__header--women">
                  {{ $t('mynBala.Женщин') }}
                </th>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row" class=" mb-stat-region-table__header mb-stat-region-table__header--blue">Количество</td>
                <td v-if="genderStats.male_total">{{ genderStats.male_total.count | Numerize }}</td>
                <td v-if="genderStats.female_total">{{ genderStats.female_total.count | Numerize }}</td>
                <td v-if="genderStats.male_first_stage">{{ genderStats.male_first_stage.count | Numerize }}</td>
                <td v-if="genderStats.female_first_stage">{{ genderStats.female_first_stage.count | Numerize }}</td>
                <td v-if="genderStats.male_second_stage">{{ genderStats.male_second_stage.count | Numerize }}</td>
                <td v-if="genderStats.female_second_stage">{{ genderStats.female_second_stage.count | Numerize }}</td>
                <td v-if="genderStats.male_enrolled">{{ genderStats.male_enrolled.count | Numerize }}</td>
                <td v-if="genderStats.female_enrolled">{{ genderStats.female_enrolled.count | Numerize }}</td>
              </tr>
              <tr class="mb-stat-region-table__row-main">
                <td scope="row" class=" mb-stat-region-table__header mb-stat-region-table__header--blue">Процент</td>
                <td v-if="genderStats.male_total">{{ Math.round((genderStats.male_total.percent) * 100) / 100 }}%</td>
                <td v-if="genderStats.female_total">{{
                    Math.round((genderStats.female_total.percent) * 100) / 100
                  }}%
                </td>
                <td v-if="genderStats.male_first_stage">{{
                    Math.round((genderStats.male_first_stage.percent) * 100) / 100
                  }}%
                </td>
                <td v-if="genderStats.female_first_stage">
                  {{ Math.round((genderStats.female_first_stage.percent) * 100) / 100 }}%
                </td>
                <td v-if="genderStats.male_second_stage">
                  {{ Math.round((genderStats.male_second_stage.percent) * 100) / 100 }}%
                </td>
                <td v-if="genderStats.female_second_stage">
                  {{ Math.round((genderStats.female_second_stage.percent) * 100) / 100 }}%
                </td>
                <td v-if="genderStats.male_enrolled">{{
                    Math.round((genderStats.male_enrolled.percent) * 100) / 100
                  }}%
                </td>
                <td v-if="genderStats.female_enrolled">{{
                    Math.round((genderStats.female_enrolled.percent) * 100) / 100
                  }}%
                </td>
              </tr>
            </table>
          </div>

          <div class="d-flex flex-row align-items-center mb-4">
            <div class="mb-rating__sub-title m-0">
              {{ $t('mynBala.Тесты1этапаолимпиадыМынбала') }}
            </div>
            <div class="select select--region ml-5">
              <el-select
                  @change="filterStage"
                  :placeholder="$t('mynBala.Год')"
                  v-model="filterStageYear"
                  filterable>
                <el-option
                    :key="0+8"
                    :label="$t('МедальЕлбасы.ПоказатьВсе')"
                    :value="0">
                </el-option>
                <el-option
                    v-for="item in years"
                    :key="item.id+9"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="mb-rating__table-block mb-rating__table-block--schools">
            <table v-if="filterStageYear==2021"
                   class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--tests">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="2" colspan="1"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue"></td>
                <td rowspan="1" colspan="10" class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Количествовопросовпопредметам') }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th v-for="item in stage2021_1.subjects" :key="item+10" rowspan="1"
                    class="mb-stat-region-table__header">
                  {{ item | Numerize }}
                </th>
              </tr>
              <div v-for="(item,index) in stage2021_1.data" :key="index+11" style="display:contents">
                <tr rowspan="1" class="mb-stat-region-table__row-main">
                  <td scope="row" class=" mb-stat-region-table__header mb-stat-region-table__header--blue">
                    <span v-if="locale=='ru'">{{ item.quiz.title_ru }}</span>
                    <span v-if="locale=='kz'">{{ item.quiz.title_kz }}</span>
                  </td>
                  <td v-for="(quest,index) in item.questions" :key="index+12" rowspan="1"
                      class="mb-stat-region-table__header">
                    {{ quest ? quest.number : '' | Numerize }}
                  </td>
                </tr>
              </div>
            </table>
            <table v-if="filterStageYear!=2021"
                   class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--tests">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="2" colspan="1"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue"></td>
                <td rowspan="1" colspan="10" class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Количествовопросовпопредметам') }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th v-for="item in stage[1].subjects" :key="item+13" rowspan="1"
                    class="mb-stat-region-table__header">
                  {{ item }}
                </th>
              </tr>
              <div v-for="item in stage[1].data" :key="item+14" style="display:contents">
                <tr v-if="item!=null" rowspan="1" class="mb-stat-region-table__row-main">
                  <td scope="row" class=" mb-stat-region-table__header mb-stat-region-table__header--blue">
                    <span v-if="locale=='ru'">{{ item.quiz.title_ru }}</span>
                    <span v-if="locale=='kz'">{{ item.quiz.title_kz }}</span>
                  </td>
                  <td v-for="quest in item.questions" :key="quest+15" rowspan="1"
                      class="mb-stat-region-table__header">
                    {{ quest ? quest.number : '' | Numerize }}
                  </td>
                </tr>
              </div>
            </table>
          </div>

          <div class="d-flex flex-row align-items-center mb-4">
            <div class="mb-rating__sub-title m-0">
              {{ $t('mynBala.Тесты2этапаолимпиадыМынбала') }}
            </div>
            <div class="select select--region ml-5">
              <el-select
                  @change="filterStage"
                  :placeholder="$t('mynBala.Год')"
                  v-model="filterStageYear"
                  filterable>
                <el-option
                    v-for="item in years"
                    :key="item.id+15"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="mb-rating__table-block mb-rating__table-block--schools">
            <table v-if="filterStageYear!=2021"
                   class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--tests">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="2" colspan="1"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue"></td>
                <td rowspan="1" colspan="10" class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Количествовопросовпопредметам') }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th v-for="item in stage[2].subjects" :key="item+17" rowspan="1"
                    class="mb-stat-region-table__header">
                  {{ item }}
                </th>
              </tr>
              <div v-for="item in stage[2].data" :key="item+18" style="display:contents">
                <tr v-if="item!=null" rowspan="1" class="mb-stat-region-table__row-main">
                  <td scope="row" class=" mb-stat-region-table__header mb-stat-region-table__header--blue">
                    <span v-if="locale=='ru'">{{ item.quiz.title_ru }}</span>
                    <span v-if="locale=='kz'">{{ item.quiz.title_kz }}</span>
                  </td>
                  <td v-for="quest in item.questions" :key="quest+19" rowspan="1"
                      class="mb-stat-region-table__header">
                    {{ quest ? quest.number : '' | Numerize }}
                  </td>
                </tr>
              </div>
            </table>
            <table v-if="filterStageYear==2021"
                   class="mb-stat-region__table mb-stat-region-table mb-stat-region-table--tests">
              <tr class="mb-stat-region-table__header-1">
                <td rowspan="2" colspan="1"
                    class="mb-stat-region-table__header mb-stat-region-table__header--blue"></td>
                <td rowspan="1" colspan="10" class="mb-stat-region-table__header mb-stat-region-table__header--blue">
                  {{ $t('mynBala.Количествовопросовпопредметам') }}
                </td>
              </tr>
              <tr class="mb-stat-region-table__header-2">
                <th v-for="item in  stage2021_2.subjects" :key="item+20" rowspan="1"
                    class="mb-stat-region-table__header">
                  {{ item }}
                </th>
              </tr>
              <div v-for="(item,index) in stage2021_2.data" :key="index+21" style="display:contents">
                <tr rowspan="1" class="mb-stat-region-table__row-main">
                  <td scope="row" class=" mb-stat-region-table__header mb-stat-region-table__header--blue">
                    <span v-if="locale=='ru'">{{ item.quiz.title_ru }}</span>
                    <span v-if="locale=='kz'">{{ item.quiz.title_kz }}</span>
                  </td>
                  <td v-for="(quest,index) in item.questions" :key="index+22" rowspan="1"
                      class="mb-stat-region-table__header">
                    {{ quest ? quest.number : '' | Numerize }}
                  </td>
                </tr>
              </div>
            </table>
          </div>
        </div>
      </section>

      <section class="sshod">
        <div class="container">
          <div class="title">
            {{ $t('mynBala.РаспределениесСШОД') }}
          </div>
          <div class="sshod__row">
            <div v-if="sshod__chartSeries.length" class="sshod__chart">
              <div id="chart">
                <apexcharts type="donut" height="543" :options="sshod__chartOptions"
                            :series="sshod__chartSeries"></apexcharts>
              </div>
            </div>
            <div class="sshod__cols">
              <div class="sshod__col-cards">
                <div class="sshod__card">
                  <div class="sshod__card-title">
                    {{ $t('mynBala.ОбщееКоличествоСвободныхМест') }}
                  </div>
                  <div class="sshod__card-amount">
                    {{ sshod.total_places | Numerize }}
                  </div>
                </div>
                <div class="sshod__card">
                  <div class="sshod__card-title">
                    {{ $t('mynBala.Свободныхместв7классесказахскимязыкомобучения') }}
                  </div>
                  <div class="sshod__card-amount">
                    {{ sshod.total_places_kaz | Numerize }}
                  </div>
                </div>
                <div class="sshod__card">
                  <div class="sshod__card-title">
                    {{ $t('mynBala.Свободныхместв7классесрусскимязыкомобучения') }}
                  </div>
                  <div class="sshod__card-amount">
                    {{ sshod.total_places_rus | Numerize }}
                  </div>
                </div>
              </div>
              <div class="sshod__col-card">
                <ul class="sshod__regions-list" style="cursor:pointer!important">
                  <li v-bind:class="{ 'sshod__region-item': currentRegionChart=='' }" @click="filterChart('')">
                    {{ $t('Qabilet.ПоказатьВсе') }}
                  </li>
                  <li v-bind:class="{ 'sshod__region-item': currentRegionChart==region.id }"
                      @click="filterChart(region.id)" v-for="region in regions" :key="region.id+23">
                    <span>{{ region.id }}</span> {{ region.name }}
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="distribution-sshod">
        <div class="container">
          <div class="mb-rating__sub-title">
            {{ $t('mynBala.РаспределениевСШОДПобедителейолимпиады') }}
          </div>
          <div class="mb-rating__table-block mb-rating__table-block--schools">
            <table class="rating-table table table-striped rating-table--winners">
              <thead>
              <tr>
                <th scope="col" class="rating-table__header">{{ $t('Qabilet.Регион') }}</th>
                <th scope="col" class="rating-table__header"> {{ $t('mynBala.русский') }}</th>
                <th scope="col" class="rating-table__header"> {{ $t('mynBala.казахский') }}</th>
                <th scope="col" class="rating-table__header"> {{ $t('mynBala.Итого') }}</th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Мужской') }}</th>
                <th scope="col" class="rating-table__header">{{ $t('mynBala.Женский') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,index) in  regionPlaces" :key="index+24">
                <td scope="row">
                  <div>
                    {{ regions[index].name }}
                  </div>
                  <div>
                    {{ $t('mynBala.Подализаявкунапоступление') }}
                  </div>
                </td>
                <td>
                  <div>{{ item[index + 1].rus_all | Numerize }} {{ $t('mynBala.мест') }}</div>
                  <div>{{ item[index + 1].rus_applied | Numerize }} {{ $t('mynBala.чел') }}.</div>
                </td>
                <td>
                  <div>{{ item[index + 1].kaz_all }} {{ $t('mynBala.мест') }}</div>
                  <div>{{ item[index + 1].kaz_applied }} {{ $t('mynBala.чел') }}.</div>
                </td>
                <td>
                  <div>{{ item[index + 1].total | Numerize }} {{ $t('mynBala.мест') }}</div>
                  <div>{{ item[index + 1].total_applied | Numerize }} {{ $t('mynBala.чел') }}.</div>
                </td>
                <td>
                  <div>{{ item[index + 1].male | Numerize }} {{ $t('mynBala.чел') }}.</div>
                </td>
                <td>
                  <div>{{ item[index + 1].female  | Numerize }} {{ $t('mynBala.чел') }}.</div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section class="olympiad-comparison">
        <div class="container">
          <div class="d-flex flex-row align-items-center mb-4">
            <div class="mb-rating__sub-title m-0 mr-3">
              {{ $t('mynBala.Сравнение_показателей_олимпиады_по_годам') }}
            </div>
            <div class="d-flex flex-row">
              <div class="select select--region mr-2">
                <el-select
                    @change="filterChartsBlock"
                    v-model="yearFrom"
                    filterable
                    placeholder="Год от"
                >
                  <el-option
                      v-for="item in years"
                      :key="item.id+25"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div class="select select--region">
                <el-select
                    @change="filterChartsBlock"
                    v-model="yearTo"
                    filterable
                    placeholder="Год до"
                >
                  <el-option
                      v-for="item in years"
                      :key="item.id+26"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>

          </div>

          <div class="olympiad-comparison__cards" v-if="!loadChart">
            <div class="comparison-card">
              <div class="comparison-card__title">{{ $t('mynBala.Подализаявкунапоступление') }}</div>
              <div class="comparison-card__chart">
                <apexcharts type="bar" height="350" :options="chartOptionsYear" :series="seriesYearTotal"></apexcharts>
              </div>
            </div>
            <div class="comparison-card">
              <div class="comparison-card__title">{{ $t('mynBala.ПрошлиТест1ЭтапаОнлайн') }}</div>
              <div class="comparison-card__chart">
                <apexcharts type="bar" height="350" :options="chartOptionsYear"
                            :series="seriesYearFirstStage"></apexcharts>
              </div>
            </div>
            <div class="comparison-card">
              <div class="comparison-card__title">{{ $t('mynBala.ПрошлиТест2ЭтапаОнлайн') }}</div>
              <div class="comparison-card__chart">
                <apexcharts type="bar" height="350" :options="chartOptionsYear"
                            :series="seriesYearSecondStage"></apexcharts>
              </div>
            </div>
            <div class="comparison-card">
              <div class="comparison-card__title">{{ $t('mynBala.РаспределениесСШОД') }}</div>
              <div class="comparison-card__chart">
                <apexcharts type="bar" height="350" :options="chartOptionsYear"
                            :series="seriesYearEnroled"></apexcharts>
              </div>
            </div>
          </div>


        </div>
      </section>
    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexcharts: VueApexCharts,
  },
  name: 'mynBala',
  data() {
    return {
      statsCurrentPage: 1,
      sortColumn: '',
      sortBy: 'desc',
      region_id_mynbalaNum: '',
      region_id_gender: 4,
      qabiletDateTo: '',
      qabiletDateFrom: '',
      filterLoading: true,
      schoolRatingRegionId: 4,
      meta: 1,
      CurrentPage: 1,
      yearFrom: '',
      yearTo: '',
      loading: true,
      filterStageYear: '',
      seriesYearTotal: [],
      region_id_school: 4,
      seriesYearFirstStage: [],
      seriesYearSecondStage: [],
      seriesYearEnroled: [],
      yearStatistics: {},
      enrolled: {},
      regionPlaces: [],
      currentRegionChart: '',
      years: [],
      chartPatr: true,
      stage2021_2: {},
      loadChart: false,
      regions: [],
      time: '',
      date: '',
      locale: this.$i18n.locale,
      chartOptionsYear: {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          }
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        colors: ['#2F6BC0', '#2091F9', '#71BF4F', '#F0A400'],
        fill: {
          opacity: 1
        },
      },
      series: [0, 0, 0],
      chartOptions: {
        chart: {
          height: 350,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '10px',
                show: false,
              },
              value: {
                fontSize: '30px',
                color: '#013B8F',
                show: true,
              }
            }
          }
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
        colors: ['#F0A400', '#71BF4F', '#2F6BC0'],
        labels: [this.$t('mynBala.ВсегоПоданоЗаявок'), this.$t('mynBala.подтвержденоЗаявок'), this.$t('mynBala.отказано')],
      },
      sshod: [],
      sshod__chartSeries: [0, 0, 0, 0],
      sshod__chartOptions: {
        colors: ['#2F6EE2'],
        legend: {
          show: false,
          position: 'bottom',

        },
        chart: {
          type: 'donut',
        },

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '30px',
                  fontWeight: 600,
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  style: {},
                  fontSize: '40px',
                  fontWeight: 600,
                  color: '#013B8F',
                  show: true,
                },
                total: {
                  show: true,
                  label: this.$t('Всегошкол'),
                  color: '#013B8F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        },
        labels: [],
      },

      loadingApi: {
        regions: true,
        regionPlaces: true,
        schoolPlaces: true,
        stage: true,
        gender: true,
        schools: true,
        regionsRating: true,
        participation: true,
        enrolled: true,
        yearStat: true,
        loadChart: false
      },
      tableRevertSchool: {
        number: '',
        first_stage: '',
        second_stage: '',
      },
      tableRevert: {
        number: '',
        first_stage: '',
        second_stage: '',
        enrolled: ''
      },
    }
  },
  mounted() {
    if (this.region_id == 0) {
      this.region_id = ''
    }
    // var currentYear = new Date().getFullYear()
    // var max = currentYear
    // for (var year = 2021; year <= max; year++) {
    //   this.years.push({'name': year, 'id': year})
    // }
    // this.qabiletDateFrom =this.years[this.years.length - 2].name
    // this.qabiletDateTo = this.years[this.years.length - 1].name
    // this.yearFrom = this.years[0].name
    // this.yearTo = this.years[this.years.length - 1].name

    var currentYear = new Date().getFullYear()
    var max = currentYear
    for (var year = 2021; year <= 2022; year++) {
      this.years.push({'name': year, 'id': year})
    }
    this.qabiletDateFrom = 2021
    this.qabiletDateTo = 2021
    this.yearFrom = 2021
    this.yearTo = 2021


    setInterval(this.clock, 1000);
    this.qabiletDateTo = this.qabiletDateFrom
    this.$http.get(window.mynbala + '/api/references/regions')
        .then((res) => {
          this.regions = res.body.data;
          this.loadingApi.regions = false
        })
    this.$http.get(window.mynbala + `/api/dashboard/region-places?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
        .then((res) => {
          this.regionPlaces = res.body;
          this.loadingApi.regionPlaces = false
        })
    this.$http.get(window.mynbala + `/api/dashboard/school-places?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
        .then((res) => {
          this.sshod = res.body
          this.sshod__chartSeries = []
          this.sshod__chartOptions.labels = []
          for (var x = 0; x < res.body.schools_by_regions.length; x++) {
            this.sshod__chartSeries.push(res.body.schools_by_regions[x].number)
            this.sshod__chartOptions.labels.push(res.body.schools_by_regions[x].name)
          }
          this.loadingApi.schoolPlaces = false
        })
    this.$http.get(window.mynbala + '/api/dashboard/first-stage?year=' + this.years[this.years.length - 1].name)
        .then((res) => {
          this.filterStageYear = this.years[this.years.length - 1].name
          this.stage = res.body;
          this.stage2021_1 = res.body[1]
          this.stage2021_1.data = [res.body[1].data[0], res.body[1].data[0]]
          this.stage2021_2 = res.body[2]
          this.stage2021_2.data = [res.body[2].data[2], res.body[2].data[4], res.body[2].data[5], res.body[2].data[6]]
          this.loadingApi.stage = false
        })
    this.$http.get(window.mynbala + `/api/dashboard/gender-statistics?region=4&from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
        .then((res) => {
          this.genderStats = res.body;
          this.loadingApi.gender = false
        })
    this.$http.get(window.mynbala + `/api/dashboard/schools-rating?region=4&from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
        .then((res) => {
          this.meta = res.body.last_page
          this.CurrentPage = res.body.current_page;
          this.schools = res.body.data;
          this.filterLoading = false
          this.loadingApi.schools = false
        })
    this.$http.get(window.mynbala + `/api/dashboard/regions-rating?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
        .then((res) => {
          this.regionsRating = res.body;
          this.loadingApi.regionsRating = false
        })
    this.$http.get(window.mynbala + `/api/dashboard/participation-statistics?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
        .then((res) => {
          this.participation = res.body;
          var participationtotal = (this.participation[6].all.total + this.participation[5].all.total)
          this.series[0] = Math.round(((this.participation[6].all.total + this.participation[5].all.total) / participationtotal * 100) * 100) / 100
          this.series[1] = Math.round(((this.participation[6].approved.total + this.participation[5].approved.total) / participationtotal * 100) * 100) / 100
          this.series[2] = Math.round(((this.participation[6].declined.total + this.participation[5].declined.total) / participationtotal * 100) * 100) / 100
          this.loadingApi.participation = false
        })
    this.$http.get(window.mynbala + `/api/dashboard/enrolled?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
        .then((res) => {
          this.enrolled = res.body
          this.loadingApi.enrolled = false
        })
    this.$http.get(window.mynbala + `/api/dashboard/year-statistics?from=${this.years[0].name}&to=${this.years[this.years.length - 1].name}`)
        .then((res) => {
          let y;
          let z;
          this.chartOptionsYear.xaxis.categories = res.body.enrolled.map(x => x.year_of_participation)
          var arr = res.body.enrolled.map(x => x.year_of_participation)

          this.yearStatistics = res.body


          for (var i = 0; i < res.body.total.length; i++) {
            z = res.body.total.map(x => x.number);
            for (y = 0; y < res.body.total.map(x => x.number).length; y++) {
              if (y != i) {
                z[y] = 0
              }
            }
            this.seriesYearTotal.push(
                {
                  name: res.body.total[i].year_of_participation,
                  data: z
                })
          }


          for (i = 0; i < res.body.first_stage.length; i++) {
            z = res.body.first_stage.map(x => x.number);
            for (y = 0; y < res.body.first_stage.map(x => x.number).length; y++) {
              if (y != i) {
                z[y] = 0
              }
            }
            this.seriesYearFirstStage.push(
                {
                  name: res.body.first_stage[i].year_of_participation,
                  data: z
                })
          }


          for (i = 0; i < res.body.second_stage.length; i++) {
            z = res.body.second_stage.map(x => x.number);
            for (y = 0; y < res.body.second_stage.map(x => x.number).length; y++) {
              if (y != i) {
                z[y] = 0
              }
            }
            this.seriesYearSecondStage.push(
                {
                  name: res.body.second_stage[i].year_of_participation,
                  data: z
                })
          }

          for (i = 0; i < res.body.enrolled.length; i++) {
            z = res.body.enrolled.map(x => x.number);
            for (y = 0; y < res.body.enrolled.map(x => x.number).length; y++) {
              if (y != i) {
                z[y] = 0
              }
            }
            this.seriesYearEnroled.push(
                {
                  name: res.body.enrolled[i].year_of_participation,
                  data: z
                })
          }
          this.loadingApi.yearStat = false

        })


  },
  filters: {
    Numerize: function (value) {
      if (value == 0) return '0'
      if (!value) return ''
      if (value <= 999) return value
      return value.toLocaleString().replace(',', ' ')
    }
  },
  watch: {
    'loadingApi': {
      handler(newValue, oldValue) {
        this.loading = !Object.values(this.loadingApi).every(elem => elem == false)

      },
      deep: true
    }
  },

  methods: {
    regionsRatingRevertSchool(column) {
      let item;
      if (this.tableRevertSchool[column] == '') {
        item = 'desc'
        this.tableRevertSchool.number = ''
        this.tableRevertSchool.first_stage = ''
        this.tableRevertSchool.second_stage = ''
        this.tableRevertSchool.enrolled = ''
        this.tableRevertSchool[column] = 'desc'
      } else if (this.tableRevertSchool[column] == 'desc') {
        item = 'asc'
        this.tableRevertSchool.number = ''
        this.tableRevertSchool.first_stage = ''
        this.tableRevertSchool.second_stage = ''
        this.tableRevertSchool.enrolled = ''
        this.tableRevertSchool[column] = 'asc'
      } else if (this.tableRevertSchool[column] == 'asc') {
        item = ''
        this.tableRevertSchool.number = ''
        this.tableRevertSchool.first_stage = ''
        this.tableRevertSchool.second_stage = ''
        this.tableRevertSchool.enrolled = ''
        this.tableRevertSchool[column] = ''
      }
      if (item == '') {
        column = ''
      }
      this.$http.get(window.mynbala + '/api/dashboard/schools-rating?region=' + this.region_id_school + `&from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31&sort[field]=${column}&sort[direction]=${item}&page=${this.CurrentPage}`)
          .then((res) => {
            this.schools = res.body.data;
            this.meta = res.body.last_page
            this.filterLoading = false
          })
    },
    regionsRatingRevert(column) {
      let item;
      if (this.tableRevert[column] == '') {
        item = 'desc'
        this.tableRevert.number = ''
        this.tableRevert.first_stage = ''
        this.tableRevert.second_stage = ''
        this.tableRevert.enrolled = ''
        this.tableRevert[column] = 'desc'
      } else if (this.tableRevert[column] == 'desc') {
        item = 'asc'
        this.tableRevert.number = ''
        this.tableRevert.first_stage = ''
        this.tableRevert.second_stage = ''
        this.tableRevert.enrolled = ''
        this.tableRevert[column] = 'asc'
      } else if (this.tableRevert[column] == 'asc') {
        item = ''
        this.tableRevert.number = ''
        this.tableRevert.first_stage = ''
        this.tableRevert.second_stage = ''
        this.tableRevert.enrolled = ''
        this.tableRevert[column] = ''
      }
      if (item == '') {
        column = ''
      }
      this.$http.get(window.mynbala + `/api/dashboard/regions-rating?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31&sort[field]=${column}&sort[direction]=${item}`)
          .then((res) => {
            this.regionsRating = Object.values(res.body);
            this.loadingApi.regionsRating = false
          })
    },
    filterMynbalaNUM() {
      if (this.region_id_mynbalaNum == 0) {
        this.region_id_mynbalaNum = ''
      }
      this.chartPatr = false
      this.$http.get(window.mynbala + `/api/dashboard/participation-statistics?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31&region=${this.region_id_mynbalaNum}`)
          .then((res) => {
            this.participation = res.body;
            var participationtotal = (this.participation[6].all.total + this.participation[5].all.total)
            this.series[0] = Math.round(((this.participation[6].all.total + this.participation[5].all.total) / participationtotal * 100) * 100) / 100
            this.series[1] = Math.round(((this.participation[6].approved.total + this.participation[5].approved.total) / participationtotal * 100) * 100) / 100
            this.series[2] = Math.round(((this.participation[6].declined.total + this.participation[5].declined.total) / participationtotal * 100) * 100) / 100
            this.chartPatr = true
          })
    },
    filterGender() {
      if (this.region_id_gender == 0) {
        this.region_id_gender = ''
      }
      this.$http.get(window.mynbala + `/api/dashboard/gender-statistics?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31&region=${this.region_id_gender}`)
          .then((res) => {
            this.genderStats = res.body;
          })
    },
    statsFilter(page = 1) {
      if (this.filterLoading == false) {
        if (page <= this.meta && page >= 1) {
          this.filterLoading = true
          this.CurrentPage = page
          this.$http.get(window.mynbala + `/api/dashboard/schools-rating?region=${this.schoolRatingRegionId}&page=${page}&from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
              .then((res) => {
                this.schools = res.body.data
                this.meta = res.body.last_page
                this.filterLoading = false
              })
        }
      }
    },
    updateInfo() {
      this.qabiletDateTo = this.qabiletDateFrom
      var max = new Date().getFullYear()
      this.yearFrom = this.years[0].name
      this.yearTo = this.years[this.years.length - 1].name
      setInterval(this.clock, 1000);
      this.$http.get(window.mynbala + '/api/references/regions')
          .then((res) => {
            this.regions = res.body.data;
            this.loadingApi.regions = false
          })
      this.loadingApi.regionPlaces = true
      this.$http.get(window.mynbala + `/api/dashboard/region-places?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.regionPlaces = res.body;
            this.loadingApi.regionPlaces = false
          })
      this.loadingApi.schoolPlaces = true
      this.$http.get(window.mynbala + `/api/dashboard/school-places?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.sshod = res.body
            this.sshod__chartSeries = []
            this.sshod__chartOptions.labels = []
            for (var x = 0; x < res.body.schools_by_regions.length; x++) {
              this.sshod__chartSeries.push(res.body.schools_by_regions[x].number)
              this.sshod__chartOptions.labels.push(res.body.schools_by_regions[x].name)
            }
            this.loadingApi.schoolPlaces = false
          })
      this.loadingApi.gender = true
      this.$http.get(window.mynbala + `/api/dashboard/gender-statistics?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.genderStats = res.body;
            this.loadingApi.gender = false
          })
      this.loadingApi.schools = true
      this.$http.get(window.mynbala + `/api/dashboard/schools-rating?region=16&from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.meta = res.body.last_page
            this.CurrentPage = res.body.current_page;
            this.schools = res.body.data;
            this.filterLoading = false
            this.loadingApi.schools = false
          })
      this.loadingApi.regionsRating = true
      this.$http.get(window.mynbala + `/api/dashboard/regions-rating?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.regionsRating = res.body;
            this.loadingApi.regionsRating = false
          })
      this.loadingApi.participation = true
      this.$http.get(window.mynbala + `/api/dashboard/participation-statistics?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.participation = res.body;
            var participationtotal = (this.participation[6].all.total + this.participation[5].all.total)
            this.series[0] = Math.round(((this.participation[6].all.total + this.participation[5].all.total) / participationtotal * 100) * 100) / 100
            this.series[1] = Math.round(((this.participation[6].approved.total + this.participation[5].approved.total) / participationtotal * 100) * 100) / 100
            this.series[2] = Math.round(((this.participation[6].declined.total + this.participation[5].declined.total) / participationtotal * 100) * 100) / 100
            this.loadingApi.participation = false
          })
      this.loadingApi.enrolled = true
      this.$http.get(window.mynbala + `/api/dashboard/enrolled?from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.enrolled = res.body
            this.loadingApi.enrolled = false
          })


    },
    changeLang() {
      this.loading = true
      localStorage.setItem('locale', this.locale);
      this.$i18n.locale = this.locale;
      this.$router.go()
    },
    filterChartsBlock() {
      this.loadChart = true
      if (this.yearFrom == null) {
        this.yearFrom = ''
      }
      if (this.yearTo == null) {
        this.yearTo = ''
      }
      this.seriesYearTotal = []
      this.seriesYearFirstStage = []
      this.seriesYearSecondStage = []
      this.seriesYearEnroled = []

      this.$http.get(window.mynbala + `/api/dashboard/year-statistics?from=${this.yearFrom}&to=${this.yearTo}`)
          .then((res) => {
            let y;
            let z;
            this.chartOptionsYear.xaxis.categories = res.body.enrolled.map(x => x.year_of_participation)
            var arr = res.body.enrolled.map(x => x.year_of_participation)

            this.yearStatistics = res.body


            for (var i = 0; i < res.body.total.length; i++) {
              z = res.body.total.map(x => x.number);
              for (y = 0; y < res.body.total.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearTotal.push(
                  {
                    name: res.body.total[i].year_of_participation,
                    data: z
                  })
            }


            for (i = 0; i < res.body.first_stage.length; i++) {
              z = res.body.first_stage.map(x => x.number);
              for (y = 0; y < res.body.first_stage.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearFirstStage.push(
                  {
                    name: res.body.first_stage[i].year_of_participation,
                    data: z
                  })
            }


            for (i = 0; i < res.body.second_stage.length; i++) {
              z = res.body.second_stage.map(x => x.number);
              for (y = 0; y < res.body.second_stage.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearSecondStage.push(
                  {
                    name: res.body.second_stage[i].year_of_participation,
                    data: z
                  })
            }

            for (i = 0; i < res.body.enrolled.length; i++) {
              z = res.body.enrolled.map(x => x.number);
              for (y = 0; y < res.body.enrolled.map(x => x.number).length; y++) {
                if (y != i) {
                  z[y] = 0
                }
              }
              this.seriesYearEnroled.push(
                  {
                    name: res.body.enrolled[i].year_of_participation,
                    data: z
                  })
            }
            this.loadChart = false
            this.loadingApi.yearStat = false

          })
    },


    clock() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      var yyyy = today.getFullYear();
      this.date = dd + '.' + mm + '.' + yyyy;
      var date = new Date(),
          hours = (date.getHours() < 10) ? '0' + date.getHours() : date.getHours(),
          minutes = (date.getMinutes() < 10) ? '0' + date.getMinutes() : date.getMinutes(),
          seconds = (date.getSeconds() < 10) ? '0' + date.getSeconds() : date.getSeconds();
      this.time = hours + ':' + minutes + ':' + seconds;
    },
    filterChart(id) {
      this.$http.get(window.mynbala + '/api/dashboard/school-places?region=' + id)
          .then((res) => {
            this.currentRegionChart = id
            this.sshod = res.body
            this.sshod__chartSeries = []
            this.sshod__chartOptions.labels = []
            for (var x = 0; x < res.body.schools_by_regions.length; x++) {
              this.sshod__chartSeries.push(res.body.schools_by_regions[x].number)
              this.sshod__chartOptions.labels.push(res.body.schools_by_regions[x].name)
            }

          })
    },
    filterRegionSchool(id) {
      this.schoolRatingRegionId = id
      this.filterLoading = true
      this.$http.get(window.mynbala + '/api/dashboard/schools-rating?region=' + id + `&from=${this.qabiletDateFrom}-01-01&to=${this.qabiletDateTo}-12-31`)
          .then((res) => {
            this.CurrentPage = 1
            this.schools = res.body.data;
            this.meta = res.body.last_page
            this.filterLoading = false
          })
    },
    filterStage(year) {
      this.$http.get(window.mynbala + '/api/dashboard/first-stage?year=' + year)
          .then((res) => {
            this.stage = res.body
            this.stage2021_1 = res.body[1]
            this.stage2021_1.data = [res.body[1].data[0], res.body[1].data[0]]
            this.stage2021_2 = res.body[2]
            this.stage2021_2.data = [res.body[2].data[0], res.body[2].data[0]]
          })
    }
  },
  changeLang() {
    localStorage.setItem('locale', this.locale);
    this.$i18n.locale = this.locale;
    this.$router.go()
  },


}
</script>
<style lang="css" scoped>
.rating-table__header-helper {
  min-width: 261px !important;
  max-width: 261px !important;
}

.rating-table__header-helper-2 {
  min-width: 150px !important;
  max-width: 150px !important;
}

.rotate180 {
  top: 29% !important;
  transform: rotate(0deg) !important;
}

.rating-table-revert {
  background-position: bottom;
  height: 10px;
  width: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  border: unset;
  background-color: unset;
  transform: rotate(180deg);
  background-image: url('/images/polygon-myb.svg');
  position: absolute;
  right: 30px;
  top: 30px;
}

.mb-card-first__info {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: auto;
}

.tasks__pagination .page-link {
  color: white;
  background: #74B2F0;
}

.tasks__pagination .page-link.active {
  background: #eff7ff;
  color: #2091f9;
}

.loader {
  background: conic-gradient(#74B2F0, transparent) !important;
}

@media (max-width: 991px) {
  .mb-card-third__desc {
    justify-content: center;
    text-align: center;
  }

  .mb-card-third__row {
    justify-content: center;
  }

  .mb-card-second__row {
    justify-content: space-between;
  }

  .flex-wrap-mob {
    flex-wrap: wrap;
  }

  .mb-card-second {
    margin-left: auto;
    margin-right: auto;
  }

  .mb-card-third {
    margin-left: auto;
    margin-right: auto;
  }

  .mb-card-first {
    width: 100%
  }
}

@media (max-width: 767px) {
  .mb-card-first__info-1 {
    padding: 26px;
  }

  .mb-card-first__info-2 {
    padding: 26px;
  }

  .mb-card-first__info-3 {
    padding: 26px;
  }

  .mb-card-first__info-4 {
    padding: 26px;
  }

  .mb-card-first__chart {
    margin: auto;
  }

  .mb-card-first__content {
    flex-direction: column;
  }

  .comparison-card {
    width: 100%;
  }

  .olympiad-comparison__cards {
    flex-direction: column;
  }

  .sshod__col-cards {
    margin-right: 0;
  }

  .sshod__cols {
    flex-direction: column;
  }

  .sshod__card {
    max-width: unset;
    width: 100%;
    margin-bottom: 1rem !important;
  }

  .sshod__chart {
    max-width: unset;
    width: 100%;
  }
}

</style>
